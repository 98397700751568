.news-page-content :is(h1, h2, h3, h4, h5, h6):not(:first-child) {
  margin-top: 0.5em;
}
.news-page-content :is(h1, h2, h3, h4, h5, h6):not(:last-child) {
  margin-bottom: 0.5em;
}
.news-page-content li {
  margin-left: 2rem;
}

.news-page-content p {
  margin: 0.3rem 0;
}
