*,
:before,
:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
  background-color: #000;
  /* background-image: url(/public/bg.webp);
  background-size: cover;
  background-repeat: no-repeat; */
}

.ant-app {
  color: #fff;
}

html::before {
  content: "";
  opacity: 0.25;
  z-index: -2;
  display: block;
  position: fixed;
  inset: -4%;
  background-image: url(/public/bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(4px);
}
