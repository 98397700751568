.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.social {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: gray;
  transition: transform 0.15s ease;
}
.social img {
  display: block;
  max-width: 70%;
  pointer-events: none;
}
.social:hover {
  transform: scale(1.1);
}
